import Calculadora from './Calculadora';

function App() {
  return (
    <div>
      <Calculadora />
    </div>
  );
}

export default App;

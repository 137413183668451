import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import './styles.css';

const Calculadora = () => {
  
  const [incluyeMaternidad, setIncluyeMaternidad] = useState('No');
  const [showError, setShowError] = useState(false);
  // const [isSiguienteEnabled, setIsSiguienteEnabled] = useState(false);
  const [nivelCobertura, setNivelCobertura] = useState(1.00);
  const [totalAdicionales, setTotalAdicionales] = useState(0);
  const [deducible, setDeducible] = useState(12000);
  const [coaseguro, setCoaseguro] = useState(10);
  const [honorarios, setHonorarios] = useState(25);
  const [sumaAsegurada, setSumaAsegurada] = useState(500000)
  const [edadAseguradoTitular, setEdadAseguradoTitular] = useState(0);
  const [generoAseguradoTitular, setGeneroAseguradoTitular] = useState("Masculino");  
  const [edades, setEdades] = useState([]);
  const [generos, setGeneros] = useState([]);
  const [edadesOtroCalculo] = useState([]);
  const [generosOtroCalculo] = useState([]);
  const [tarifa, setTarifa] = useState(null);
  const [tarifasAsegurados, setTarifasAsegurados] = useState([]);
  const [mostrarFormulario, setMostrarFormulario] = useState(true);

  // const [mostrarCampos, setMostrarCampos] = useState(true);

  // const siguiente = () => {
  //   if(edadAseguradoTitular > 0) {
  //     setShowError(false);
  //     // setIsSiguienteEnabled(true);
  //   }else {
  //     setShowError(true)
  //     // setIsSiguienteEnabled(false);
  //   }
  //   setMostrarCampos(false);
  //   setMostrarNCSA(true);
  // };

  /////////////////////////////////////
  // const atras2 = () => {
  //   setMostrarCampos(true);
  //   setMostrarNCSA(false);
  //   // setIsSiguienteEnabled(false);
  // };
  // const siguiente2 = () => {
  //   setMostrarCEDble(true);
  //   setMostrarNCSA(false);
  // };

  /////////////////////////////////////
  // const siguiente3 = () => {
  //   setMostrarCEDble(false);
  //   setMostrarDbleAcc(true);
  // };
  // const atras3 = () => {
  //   setMostrarNCSA(true);
  //   setMostrarCEDble(false);
  // };
  /////////////////////////////////////
  const reloadPage = () => {
    window.location.reload();
  };
  /////////////////////////////////////

  const aplicacionFactores = nivelCobertura * deducible * coaseguro * honorarios * sumaAsegurada * 0.83
  const [mostrarSeccion, setMostrarSeccion] = useState(false);
  const [tipoCotizacion, setTipoCotizacion] = useState('Individual');
  // const [mostrarNCSA, setMostrarNCSA] = useState(false);
  // const [mostrarCEDble, setMostrarCEDble] = useState(false);
  // const [mostrarDbleAcc, setMostrarDbleAcc] = useState(false);
  const [mostrarNumeroAsegurados, setMostrarNumeroAsegurados] = useState(false);
  const [numeroAsegurados, setNumeroAsegurados] = useState(0);
  const tasaIVA = 0.16;
  const factorDesdeHasta = 0.35;
  const coberturaDentalVision = 241.54;
  const derechoPoliza = 750;
  const asistencias = 172.38
  const deducibleAccidente = 1077.41;
  const coberturaExtranjero = 0; //si se habilita sumar 457.18
  const ayudaMaternidad = 2088.39;
  const costosCoberturasSeguros = tarifa * aplicacionFactores;
  const sumaTarifas = tarifasAsegurados.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
  const costosCoberturasSegurosAsegurados = sumaTarifas * aplicacionFactores; // Aqui está el costo por cada asegurado que se ingresa
  const primaNetaTotalAnual = costosCoberturasSeguros + ayudaMaternidad + totalAdicionales + (mostrarNumeroAsegurados ? costosCoberturasSegurosAsegurados : 0);
  const subTotal = primaNetaTotalAnual + derechoPoliza;
  const ivaSubTotal = subTotal * tasaIVA;
  const totalAnual = ivaSubTotal + subTotal;
  // const recargoPPFraccionadoM = 1.10;
  // const recargoPPFraccionadoT = 1.07;
  // const recargoPPFraccionadoS = 1.05;
  const factorDesde = totalAnual * factorDesdeHasta;
  // const primerReciboMensual = (primaNetaTotalAnual * recargoPPFraccionadoM / 12 + derechoPoliza) * (1 + tasaIVA);
  // const primerReciboTrimestral = (primaNetaTotalAnual * recargoPPFraccionadoT / 4 + derechoPoliza) * (1 + tasaIVA);
  // const primerReciboSemestral = (primaNetaTotalAnual * recargoPPFraccionadoS / 2 + derechoPoliza) * (1 + tasaIVA);
  // const recibosSubsecuentesMensual = (primaNetaTotalAnual * recargoPPFraccionadoM / 12) * (1 + tasaIVA);
  // const recibosSubsecuentesTrimestral = (primaNetaTotalAnual * recargoPPFraccionadoT / 4) * (1 + tasaIVA);
  // const recibosSubsecuentesSemestral = (primaNetaTotalAnual * recargoPPFraccionadoS / 2) * (1 + tasaIVA);
  // const totalMensual = primerReciboMensual + recibosSubsecuentesMensual * 11;
  // const totalTrimestral = primerReciboTrimestral + recibosSubsecuentesTrimestral * 3;
  // const totalSemestral = primerReciboSemestral + recibosSubsecuentesSemestral;
  const desdePrecio = totalAnual - factorDesde;
  const hastaPrecio = totalAnual + factorDesde;
  const calcularAdicionales = () => {
    let suma = 0;
    if (opciones.coberturaExtranjero === 'Si') {
      suma += coberturaExtranjero;
    }
    if (opciones.deducibleAccidente === 'Si') {
      suma += deducibleAccidente;
    }
    if (incluyeMaternidad === 'Si') {
      suma += ayudaMaternidad;
    }
    suma += asistencias + coberturaDentalVision;
    setTotalAdicionales(suma);
  };
  const calcularCosto = () => {
    if (edadAseguradoTitular === 0) {
      alert("Falta colocar la edad");
      return;
    }
    if (tipoCotizacion === 'Familiar' && numeroAsegurados >= 7) {
      alert("El número de asegurados no puede ser mayor a 6 para una cotización familiar");
      return;
    } if (tipoCotizacion === 'Familiar' && numeroAsegurados === 0) {
      alert("El número de asegurados no puede ser 0 para una cotización familiar");
      return;
    }
    setMostrarFormulario(false); // Oculta el formulario
    setMostrarSeccion(true); // Muestra el resultado
    if (tipoCotizacion === 'Individual') {
      setMostrarSeccion(true);
    }
    setMostrarSeccion(true);
  };
  const handleChange = (e) => {
    const selectedValue = e.target.value;

    switch (selectedValue) {
      case 'bajo':
        setNivelCobertura(0.80);
        break;
      case 'medio':
        setNivelCobertura(0.90);
        break;
      case 'alto':
        setNivelCobertura(1.00);
        break;
      default:
        setNivelCobertura(1.00);
        break;
    }
  };
  const handleNumeroAsegurados = (e) => {
    const val = e.target.value;
    let num;
    if (val === "" || val === null || val === undefined) {
      num = null;
    } else {
      num = parseInt(val, 10);
    }
  
    if (Number.isInteger(num) && num >= 0) {
      setNumeroAsegurados(num);
      setEdades(new Array(num).fill(0));
      setGeneros(new Array(num).fill('Masculino'));
    } else {
      // Manejar caso inválido si es necesario
    }
  };
  
  const setEdad2 = (indice, valor) => {
    const newEdades = [...edades];
    newEdades[indice] = valor;
    setEdades(newEdades);
  };

  const setGenero2 = (indice, valor) => {
    const newGeneros = [...generos];
    newGeneros[indice] = valor;
    setGeneros(newGeneros);
  };
  const campoVacio = (edadAseguradoTitular) => {
    if(edadAseguradoTitular > 0) {
      setShowError(false);
      // setIsSiguienteEnabled(true);
    }else {
      setShowError(true)
      // setIsSiguienteEnabled(false);
    }
  }
  const [opciones, setOpciones] = useState(() => {
    return {
      coberturaExtranjero: 'No',
      ayudaMaternidad: 'No',
      // ... otros campos
    };
  });


  useEffect(() => {
    const tarifas = [];
    setMostrarNumeroAsegurados(tipoCotizacion === 'Familiar');
    fetch("/Tarifa.xlsx")
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const workbook = XLSX.read(buffer, { type: "buffer" });
        const sheetName1 = workbook.SheetNames[0];
        const sheetName2 = workbook.SheetNames[1];
        const sheetName3 = workbook.SheetNames[2];
        const sheetName4 = workbook.SheetNames[3];
        const sheetName5 = workbook.SheetNames[4];
        const worksheet1 = workbook.Sheets[sheetName1];
        const worksheet2 = workbook.Sheets[sheetName2];
        const worksheet3 = workbook.Sheets[sheetName3];
        const worksheet4 = workbook.Sheets[sheetName4];
        const worksheet5 = workbook.Sheets[sheetName5];
        const data = XLSX.utils.sheet_to_json(worksheet1);
        const data2 = XLSX.utils.sheet_to_json(worksheet2);
        const data3 = XLSX.utils.sheet_to_json(worksheet3);
        const data4 = XLSX.utils.sheet_to_json(worksheet4);
        const data5 = XLSX.utils.sheet_to_json(worksheet5);

        //Esto corresponde a la hoja 1 de Tarifa para hombres y mujeres 
        const objetoEdad = data.find(row => row['Edad'] === Number(edadAseguradoTitular));
        if (objetoEdad) {
          const valorCorrespondiente = generoAseguradoTitular === 'Masculino' ? objetoEdad['Hombres'] : objetoEdad['Mujeres'];
          setTarifa(valorCorrespondiente);
        }
        //Esto corresponde a la hoja 2 de Suma Asegurada %
        const objetoSumaAsegurada = data2.find(row => row['Suma Asegurada'] === Number(sumaAsegurada));
        if (objetoSumaAsegurada) {
          setSumaAsegurada(objetoSumaAsegurada.Factor)
        }
        //Esto corresponde a la hoja 3 de Deducible %
        const objetoDeducible = data3.find(row => row['Deducible'] === Number(deducible));
        if (objetoDeducible) {
          setDeducible(objetoDeducible.Factor)
        }
        //Esto corresponde a la hoja 4 de Coaseguro %
        const objetoCoaseguro = data4.find(row => row['Coaseguro'] === Number(coaseguro));
        if (objetoCoaseguro) {
          setCoaseguro(objetoCoaseguro.Factor)
        }
        //Esto corresponde a la hoja 5 de Honorarios %
        const objetoHonorarios = data5.find(row => row['Honorarios'] === Number(honorarios));
        if (objetoHonorarios) {
          setHonorarios(objetoHonorarios.Factor)
        }

        edades.forEach((edad, i) => {
          const objetoEdad = data.find(row => row['Edad'] === Number(edad));
          
          if (objetoEdad) {
            const generoCorrespondiente = generos[i];
            const valorCorrespondiente = generoCorrespondiente === 'Masculino' ? objetoEdad['Hombres'] : objetoEdad['Mujeres'];
            tarifas.push(valorCorrespondiente);  // Almacena la tarifa en el array
            setTarifasAsegurados(tarifas);
          }
        });
      });
  }, [tipoCotizacion, edadAseguradoTitular, generoAseguradoTitular, nivelCobertura, sumaAsegurada, deducible, coaseguro, mostrarSeccion, opciones.coberturaExtranjero, opciones.deducibleAccidente, incluyeMaternidad, honorarios, edadesOtroCalculo, generosOtroCalculo, tarifasAsegurados, edades, generos]);

  return (
  <div className='container-principal'>
    <div className='calculadora-content'>
      <h1 className='presentacion'>Calcula el precio de tu Seguro de Gastos Médicos Mayores</h1>
      <div className='row g-3 form-tamano'>
        {mostrarFormulario ? (
          <>
            <div>
              <label htmlFor="inputPoliza" className="form-label">Tipo de Póliza</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  Individual: Cubre solamente al titular de la póliza. Familiar: Extiende la cobertura a los miembros de la familia del titular.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger> 
            </div>               
            <select id="inputPoliza" className="form-select" onChange={e => setTipoCotizacion(e.target.value)}>
              <option value="Individual">Individual</option>
              <option value="Familiar">Familiar</option>
            </select>
            {mostrarNumeroAsegurados && (
            <>
            <div>
              <label htmlFor="inputAsegurados" className="form-label">Número de Asegurados</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  Indica cuántas personas están cubiertas bajo la póliza. Puede ser aplicable para pólizas familiares o grupales.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger> 
            </div>
            <input className='form-control' id="inputAsegurados" type="number" min="1" max="6" placeholder="Ingresa el número de asegurados extra" onChange={handleNumeroAsegurados} />
            </>
            )}
            <div>
              <label htmlFor="input-edad" className="form-label">Edad { mostrarNumeroAsegurados ? "del Asegurado Titular" : ""}</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  Ingresa la edad del titular de la póliza.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger> 
            </div>
            <input 
              className='form-control' 
              type="number" 
              min="0" 
              max="95" 
              placeholder="Ingresa la edad del asegurado titular" 
              onChange={e => {
                const edadAseguradoTitular = e.target.value;
                setEdadAseguradoTitular(edadAseguradoTitular);
                campoVacio(edadAseguradoTitular);
              }}
            />
            {showError && <div style={{color: 'red'}}>Necesitas llenar este campo</div>}
            <div>
              <label htmlFor="input-edad" className="form-label">Género { mostrarNumeroAsegurados ? "del Asegurado Titular" : ""} </label>
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                  <Tooltip id="button-tooltip">
                    Selecciona el género de nacimiento del titular de la póliza
                  </Tooltip>
                }>
                  <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
                </OverlayTrigger> 
            </div>
            <select required className="form-select" onChange={e => setGeneroAseguradoTitular(e.target.value)}>
              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
            </select>
            {mostrarNumeroAsegurados && Number.isInteger(numeroAsegurados) && numeroAsegurados >= 0 ? 
              [...Array(numeroAsegurados)].map((_, i) => (
                <div key={i}>
                  <div>
                    <label htmlFor={`input-edad-${i}`} className="form-label">Edad del Asegurado {i + 1}</label>
                  </div>
                  <input 
                    className='form-control' 
                    id={`form-control-${i}`} 
                    type="number" 
                    min="0" 
                    max="95" 
                    onChange={(e) => setEdad2(i, e.target.value)} 
                  />

                  <div>
                    <label htmlFor={`input-genero-${i}`} className="form-label">Género del Asegurado {i + 1}</label>
                  </div>
                  <select 
                    className='form-control' 
                    id={`form-control-${i}`} 
                    onChange={(e) => setGenero2(i, e.target.value)}
                  >
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </select>
                </div>
              )) : null
            }
            <div>
              <label htmlFor="nivel" className="form-label">Nivel de Cobertura</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  El 'Nivel hospitalario' clasifica la capacidad de un hospital para ofrecer servicios médicos. 'Bajo' ofrece atención básica, 'Medio' incluye algunas especialidades, y 'Alto' ofrece servicios médicos avanzados y tecnología de punta.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger>
            </div>
            <select id="nivel" className="form-select" onChange={handleChange}>
              <option value="bajo">Nivel hospitalario bajo</option>
              <option value="medio">Nivel hospitalario medio</option>
              <option value="alto">Nivel hospitalario alto</option>
            </select>
            <div>
              <label htmlFor="inputAsegurados" className="form-label">Suma Asegurada</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  La cantidad máxima que la compañía de seguros pagará en caso de un siniestro. Representa el límite de responsabilidad de la aseguradora.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger>
            </div>               
            <select className="form-select" onChange={e => setSumaAsegurada(e.target.value)}>
              <option value="500000">$500,000</option>
              <option value="1000000">$1,000,000</option>
              <option value="2000000">$2,000,000</option>
              <option value="3000000">$3,000,000</option>
              <option value="4000000">$4,000,000</option>
              <option value="5000000">$5,000,000</option>
              <option value="10000000">$10,000,000</option>
              <option value="15000000">$15,000,000</option>
              <option value="20000000">$20,000,000</option>
              <option value="30000000">$30,000,000</option>
              <option value="40000000">$40,000,000</option>
              <option value="50000000">$50,000,000</option>
              <option value="60000000">$60,000,000</option>
              <option value="70000000">$70,000,000</option>
              <option value="80000000">$80,000,000</option>
              <option value="100000000">$100,000,000</option>
            </select>
            <div>
              <label htmlFor="inputAsegurados" className="form-label">Deducible</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  La cantidad que el asegurado debe pagar de su bolsillo antes de que la aseguradora comience a cubrir los costos.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger>
            </div>               
            <select className="form-select" onChange={e => setDeducible(e.target.value)}>
              <option value="12000">$12,000</option>
              <option value="15000">$15,000</option>
              <option value="20000">$20,000</option>
              <option value="25000">$25,000</option>
              <option value="30000">$30,000</option>
              <option value="35000">$35,000</option>
              <option value="40000">$40,000</option>
              <option value="45000">$45,000</option>
              <option value="50000">$50,000</option>
              <option value="60000">$60,000</option>
              <option value="70000">$70,000</option>
              <option value="90000">$90,000</option>
              <option value="100000">$100,000</option>
            </select>
            <div>
              <label htmlFor="inputAsegurados" className="form-label">Deducible 0 por accidente</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  Una opción que elimina el deducible en caso de un accidente, lo que significa que la aseguradora cubre la totalidad de los gastos desde el primer momento.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger>
            </div>
            <select className="form-select" onChange={e => { setOpciones({ ...opciones, deducibleAccidente: e.target.value }); calcularAdicionales(e.target.value); }}>
              <option value="No">No</option>
              <option value="Si">Si</option>
            </select>
            <div>
              <label htmlFor="inputAsegurados" className="form-label">Coaseguro</label>
              <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                <Tooltip id="button-tooltip">
                  El porcentaje de los costos cubiertos por el asegurado después de haber pagado el deducible. Por ejemplo, si el coaseguro es del 10%, el asegurado pagará el 10% de los costos adicionales después del deducible.
                </Tooltip>
              }>
                <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger>
            </div>                
            <select className="form-select" onChange={e => setCoaseguro(e.target.value)}>
              <option value="10">10%</option>
              <option value="15">15%</option>
              <option value="20">20%</option>
            </select>
            {generoAseguradoTitular === 'Femenino' && (
            <>
              <div>
                <label htmlFor="inputAsegurados" className="form-label">Ayuda para Maternidad</label>
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={
                  <Tooltip id="button-tooltip">
                    Una cobertura extra, en caso de encontrarse embarazada.
                  </Tooltip>
                }>
                  <span className="tooltip-style" style={{ cursor: 'pointer' }}>ℹ️</span>
                </OverlayTrigger>
              </div>                  
              <select className="form-select" onChange={e => setIncluyeMaternidad(e.target.value)}>
                <option value="No">No</option>
                <option value="Si">Si</option>
              </select>
            </>
            )}
            <button className='button-calc' onClick={calcularCosto}>Calcular</button>
          </>
        ) : (
          mostrarSeccion && (
            <div id="seccionTotal" className='seccion-resultado'>
              <div className='costos-class'>
                <h2 className='result-message'>Costo Aproximado</h2>
                <div className='desde-hasta'>
                  <div className='box-prizes'>
                    <p className='result-prizes'>{`Desde $${desdePrecio.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN`}</p>
                    <p className='result-prizes'>Forma de pago: Anual</p>
                  </div>
                  <div className='box-prizes'>
                    <p className='result-prizes'>{`Hasta $${hastaPrecio.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MXN`}</p>
                    <p className='result-prizes'>Forma de pago: Anual</p>
                  </div>
                </div>
                <label className='disclaimer'>
                  Este es un rango de <b>costo aproximado</b> que podría tener el seguro de Gastos Médicos Mayores con
                  base a las coberturas que has seleccionado. Este <b>rango de costos</b> te ofrece una idea preliminar
                  para que puedas valorar si un seguro de gastos médicos mayores esta dentro de tu presupuesto,
                  sin embargo, es muy importante señalar, que <b>para encontrar y crear el seguro ideal para ti y tu
                  familia, es necesario considerar una variedad de factores y variables adicionales acorde a tu
                  estilo de vida.</b>
                </label>
                <div className='content-button-NC'>
                  <button className='button-AS' onClick={reloadPage}>Crear nueva cotización</button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  </div>
);

};

export default Calculadora;
